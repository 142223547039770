import { VerificationType } from "../../../../constants";
import { request } from "../../utils/request/request";

export const resendCode = async (uuid, verificationType = VerificationType.Email) => {
    const sentData = new FormData();
    sentData.append("uuid", uuid);

    const response = await request.post(`/bg/${verificationType}/resend`, sentData);

    return response.data;
};
 
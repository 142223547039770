import React from 'react';
import './CodeCheck.scss';
import { Formik, Field, Form }  from "formik";
import * as PropTypes from "prop-types";
import * as yup from 'yup';
import Interpolate from 'react-interpolate-component';

import useLanguage from "../../../hooks/useLanguage.js";
import useTimer from '../../../hooks/useTimer.js';

import Button from "../../../components/Button.js";

import {CODE_LENGTH, MESSAGES, TIMER_TIMEOUT_MS} from './constants.js';

const CodeCheck = React.memo(({
   contact,
   seconds,
   codeErrorMessage,
   completeVerificationWithError,
   handleCheckCode,
   handleResetSeconds,
   handleResendCode,
   repeatCountLeft,
   verificationType
}) => {
   const [language] = useLanguage();
   const locales = MESSAGES[language].checkCode;

   const resetFormHandlerRef = React.useRef(null);

   const [initialValues] = React.useState({authCode: ''});

   const [isCantClickResendButton, setIsCantClickResendButton] = React.useState(true);

   const validationSchema = yup.object({
      authCode: yup
        .string(locales.formValidationIncorrectAuthCode)
        .matches(new RegExp(`^(([0-9]){${CODE_LENGTH}})$`), locales.formValidationIncorrectAuthCode)
        .required(locales.formValidationRequiredAuthCode),
    });

   const { 
      countdownMilleseconds, 
      isActive, 
      start: timerStart 
   } = useTimer(
         seconds, 
         TIMER_TIMEOUT_MS, 
         handleResetSeconds,
   );

   const countdown = new Intl.DateTimeFormat('ru-RU', {
      minute: "numeric",
      second: "numeric",
   }).format(countdownMilleseconds);


   React.useEffect(() => {
      if(isActive && countdownMilleseconds <= 0 && repeatCountLeft === 0) {
         completeVerificationWithError();
      }

      if(!isActive && resetFormHandlerRef.current) {
         resetFormHandlerRef.current();
      }

   }, [isActive, completeVerificationWithError, countdownMilleseconds, repeatCountLeft, resetFormHandlerRef]);

   React.useEffect(() => {
      if(seconds > 0) {
         timerStart();
      }   
   }, [seconds, timerStart]);

   const submitCheckCode = React.useCallback(({ authCode }, actions) => {
      handleCheckCode(authCode).finally(() => {
         actions.resetForm();
      })
   }, [handleCheckCode]);

   const resendCode = React.useCallback((event, resetForm) => {
      event.preventDefault();
      setIsCantClickResendButton(false);

      if(!isCantClickResendButton) {
         return;
      }
      
      handleResendCode().finally(() => {
         resetForm();
      })

      setTimeout(() => {
         setIsCantClickResendButton(true);
      }, 500)
   }, [handleResendCode]);

   return (
      <div className='CodeCheck'>
         <Formik
            initialStatus="default"
            initialValues={initialValues}
            onSubmit={submitCheckCode}
            validationSchema={validationSchema}
         >
            {({ errors, isSubmitting, touched, resetForm }) => {

               resetFormHandlerRef.current = resetForm;

               return (
                  <Form className={`FOSFieldFormBase`}>
                     <div className="callBackForm-HeadBlock CodeCheck-Title">
                        {locales[verificationType].formTitle}
                     </div>
                     <div>
                       {
                        contact && 
                           <Interpolate 
                              with={{
                                 contact,
                                 toContact: locales[verificationType].toContact
                              }}
                              component="p"
                              className="CodeCheck-Text"
                              >
                                 {locales.codeInfo}
                           </Interpolate>
                        }
                     </div>

                     {isActive && codeErrorMessage && 
                        <div 
                           className="callBackForm-ValidationError CodeCheck-FieldValidationError"
                        >
                           {codeErrorMessage}
                        </div>
                     }
                     
                     <label
                        className="CodeCheck-FieldLabel CodeCheck-FieldLabel" 
                        htmlFor="codeCheck"
                     >
                        {locales[verificationType].fromContact}
                     </label>

                     <Field
                        name="authCode"
                        placeholder={locales[verificationType].inputCode}
                        className="callBackForm-inputText"
                        id="codeCheck"
                        type="tel"
                        disabled={!isActive}
                        maxLength={CODE_LENGTH}
                     />

                     {isActive && errors.authCode && touched.authCode && 
                        <div 
                           className="callBackForm-ValidationError CodeCheck-FieldValidationError" 
                        >
                           {errors.authCode}
                        </div>
                     }

                     {isActive && 
                        <Interpolate 
                           with= {{
                              countdown
                           }}
                           component="p"
                           className="CodeCheck-CodeTimer"
                           >
                              {locales.resendCodeAfterSomeTime}
                        </Interpolate>
                     } 

                     {!isActive && repeatCountLeft > 0 &&
                        <a className="CodeCheck-ResendCodeLink" 
                           href="#" 
                           onClick={(event) => resendCode(event, resetForm)}
                        >
                           {locales.resendCode}
                        </a>
                     }

                     <div className="CodeCheck-ButtonBox callBackForm-BlockMessage-button">
                        <Button 
                           type="submit"
                           disabled={isSubmitting || !isActive}
                        > 
                           {locales.confirm}
                        </Button>
                     </div>
                  </Form>
               );
            }}
         </Formik>
      </div>
   );
});

CodeCheck.propTypes = {
   contact: PropTypes.string,
   seconds: PropTypes.number,
   codeErrorMessage: PropTypes.string,
   completeVerificationWithError: PropTypes.func,
   handleCheckCode: PropTypes.func,
   handleResetSeconds: PropTypes.func,
   handleResendCode: PropTypes.func,
   repeatCountLeft: PropTypes.number,
};

export default CodeCheck;

 

import { VerificationType } from "../../../../constants";
import { checkCode } from "./checkCode";
import { getCodeStatus } from "./getCodeStatus";

export const getCodeStatusCheck = async (uuid, authCode, verificationType = VerificationType.Email) => {
    try {
        const checkData = await checkCode(uuid, authCode, verificationType);

        let smsStatus = await getCodeStatus(uuid, verificationType);

        if (!smsStatus?.data) {
            smsStatus = await getCodeStatus(uuid, verificationType);
        }

        return { checkData, smsStatus };
    } catch (error) {
        return error;
    }
};
 
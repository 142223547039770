import { VerificationType } from "../../../../constants";
import { getCodeStatus } from "./getCodeStatus";
import { resendCode } from "./resendCode";

export const getCodeStatusResending = async (uuid, verificationType = VerificationType.Email) => {
    try {
        const resend = await resendCode(uuid, verificationType);
        if (resend.status === 'success' && resend?.data?.uuid) {
            const smsStatus = await getCodeStatus(resend.data.uuid, verificationType);
            return smsStatus;
        }

        return resend;

    } catch (error) {
        return error;
    }
};
 
export const ResponseStatus = {
    Success: 'success',
    Error: 'error',
}

export const VerificationType = {
    Email: 'email',
    Sms: 'sms',
}

export const ResponseCode = {
    Success: 200,
    Created: 201,

    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    Conflict: 409,
    TooManyRequests: 429,

    InternalServerError: 500,
    ServiceUnavailable: 503,
    GatewayTimeout: 504,
}

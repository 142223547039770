import { VerificationType } from "../../../../constants";
import { verificationFieldNames } from "../../../components/FormVerificationWrapper/constants";

import { request } from "../../utils/request/request";
export const getCodeStatusByContact = async (contact, verificationType = VerificationType.Email) => {
    const sentData = new FormData();

    sentData.append(verificationFieldNames[verificationType], contact);

    const response = await request.post(`/bg/${verificationType}/auth`, sentData);

    return response.data;
}; 

import { request } from "../../utils/request/request";
import { VerificationType } from "../../../../constants";

export const checkCode = async (uuid, authCode, verificationType = VerificationType.Email) => {
    const sentData = new FormData();
    sentData.append("uuid", uuid);
    sentData.append("authCode", authCode);

    const response = await request.post(`/bg/${verificationType}/check`, sentData);

    return response.data;
};
 
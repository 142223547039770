import React from "react";
import './LinkButton.scss';
const LinkButton = ({ className, href, title, onClick }) => {

    const handlerClick = React.useCallback((event) => {
        if(onClick) {
            event.preventDefault();
            onClick();
        }
    }, [onClick]);

    return ( 
        <div className="ContactBank-LinkButton">
            <a 
                className={`ContactBank-LinkButtonBtn ${className}`} 
                href={href} 
                onClick={handlerClick}
            >
                {title}
            </a>
        </div>
    );
}

export default LinkButton;
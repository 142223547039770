import React from 'react';
import * as PropTypes from "prop-types";

const ProcessScreen = ({text}) => (
    <div className="callBackForm-BlockMessage callBackForm-BlockMessageProcess">
      <div className="callBackForm-BlockMessage-text">
        {text}
      </div>
    </div>
);

ProcessScreen.propTypes = {
    text: PropTypes.string,
}

export default ProcessScreen;
import { VerificationType } from '../../../constants.js';
import enMessages from '../../../i18n/form/en.js';
import ruMessages from '../../../i18n/form/ru.js';

export const MESSAGES = {
  en: enMessages,
  ru: ruMessages,
};

export const FORM_SCREENS = {
  error: 'error',
  codeCheck: 'codeCheck',
  form: 'form',
  success: 'success',
  process: 'process',
};

export const FORM_SCREEN_DEFAULT = FORM_SCREENS.form;

export const VERIFICATION_CODE_DATA = {
  activeLeftSeconds: 0,
  checkLeftSeconds: 0,
  contact: "",
  isCanBeChecked: false,
  isChecked: false,
  repeatCountLeft: 0,
  sendCount: 0,
  uuid: "",
  codeErrorMessage: "",
}

export const SCREEN_DATA = {
  screen: FORM_SCREEN_DEFAULT,
  title: '',
  description: '',
  isButtonVisible: true,
}

export const verificationFieldNames = {
  [VerificationType.Email]: 'email',
  [VerificationType.Sms]: 'phone',
}

import React from "react";

const MILLISECONDS_IN_ONE_SECOND = 1000;

function useTimer(seconds=0, updateInterval=1000, finishCb=null) {
   const [sentAt, setSentAt] = React.useState();
   const [now, setNow] = React.useState(Date.now());
   const finishCbRef = React.useRef(finishCb);
   finishCbRef.current = finishCb;
   const timerRunningTime = seconds * MILLISECONDS_IN_ONE_SECOND;

   const start = React.useCallback(() => {
      setSentAt(Date.now());
    }, [setSentAt]);

    const clear = React.useCallback(() => {
      setSentAt();
    }, [setSentAt]);

   React.useEffect(() => {
      if (sentAt && timerRunningTime - (now - sentAt) < 0) {
         clear();
         if(finishCbRef.current) {
          finishCbRef.current();
         }
      }
   }, [clear, sentAt, timerRunningTime, now]);
 
   React.useLayoutEffect(() => {
     if (!sentAt) {
       return;
     }
 
     setNow(Date.now());
     
     const interval = setInterval(() => {
       setNow(Date.now());
     
     }, updateInterval);
 
     return () => {
       clearInterval(interval);
     };
   }, [updateInterval, sentAt]);
 
   const countdownMilleseconds = sentAt ? timerRunningTime - (now - sentAt) : 0;

   return {
      countdownMilleseconds,
      isActive: Boolean(sentAt),
      start,
      clear,
   }
}

export default useTimer;
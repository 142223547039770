export default {
  confirmationConsentProcessingPD: "Terms of Processing and the Use of my Personal Data",
  confirmationLabel: "I have read and agree to the %(linkToConsentProcessingPD)s and the %(linkToPrivacyPolicy)s",
  confirmationPrivacyPolicy: "Privacy Policy",
  
  checkCode: {
    confirm: "Confirm",
    codeFromEmail: "Code from email",
    codeInfo: "Code sent to %(contact)s. The number of messages sent is limited.",
    resendCode: "Send code again",
    resendCodeAfterSomeTime: "Send code again in %(countdown)s.",
    incorrectCodeEntered: "Incorrect code entered",
    formValidationIncorrectAuthCode: "Incorrect code",
    formValidationRequiredAuthCode: "Enter code",
    email: {
      formTitle: "Confirm email",
      toContact: "email",
      fromContact: "Code from email",
      inputCode: "Enter code from email",
    },
    sms: {
      formTitle: "Confirm phone number",
      toContact: "number",
      fromContact: "Code from sms",
      inputCode: "Enter code from sms",
    },
    codeErrorMessages: {
      codeInputLimitExceeded: "Incorrect code entered. The number of attempts to enter the code has been exceeded. Send the code again",
      codeRequestCountExceeded: "The number of code requests has been exceeded. Please verify your phone number again",
      codeTooManyRequests: {
        title: "Number of requests exceeded",
        description: "Refresh the page, wait a few minutes and fill out your request in the feedback form again",
      },
      codeNumberInputAttemptsExceeded: {
        title: "Input attempts exceeded",
        description: "Refresh the page and try resending your request"
      },
      invalidCode: "The verification code you entered is invalid",
      codeExpired: "The verification code has expired. Please resend the code",
      allResubmissionsExhausted: "All resubmissions have been exhausted",
      mainTimeHasExpired: "Verification time has expired",
      pleaseRetry: "Refresh the page and try resending your request",
    },
    codeSuccessMessages: {
      numberConfirmed: "Your request has been successfully submitted",
      ourSpecialistsWillContactYou: "Thank you for contacting us. Please wait for a response from a support specialist",
    }
  },
    
  formUser: {
    title: "Describe your request",
    result: {
      button: "Ok",
      
      success: "Thank you for contacting us!",
      successDescription: "Wait for a response from a support specialist.",
      
      error: "Something went wrong",
      errorDescription: "We are already aware of this problem and will fix it soon",
    },
  },

  formCompanyLead: {
    title: "Fill out the business inquiry form",
    result: {
      button: "Okay",
      
      success: "Thank you for your application, we will definitely contact you",
      
      error: "Something went wrong",
      errorDescription: "We are already aware of this problem and will fix everything soon",
    },
  },
    
  formProcess: "Writing down...",
  
  fields: {
    name: "First name",
    surname: "Last name",
    patronymic: "Patronymic",
    phone: "Phone",
    email: "Email",
    eMail: "Email",
    comment: "Comment",

    companyName: "Legal entity or individual entrepreneur",
    companyIdentificationNumber: "Taxpayer Identification Number",
    companyDescriptionActivity: "Describe your business",

    website: "Website",
  },
  
  fieldValidation: {
    requiredEmail: "Enter email",
    incorrectEmail: "Enter a correct email",

    requiredEMail: "Enter email", 
    incorrectEMail: "Enter a valid email",
    
    requiredCompanyOrIndividualEntrepreneur: "Enter the name of the legal entity or individual entrepreneur",
    incorrectCompanyOrIndividualEntrepreneur: "Incorrect name of the legal entity or individual entrepreneur",
    
    requiredCompanyIdentificationNumber: "Enter the company's TIN",
    incorrectCompanyIdentificationNumber: "Incorrect Taxpayer Identification Number",
    
    requiredPhone: "Enter your phone number",
    incorrectPhone: "Incorrect phone number",
    
    requaredName: "Enter your name",
    incorrectName: "Incorrect first name",
    
    incorrectPatronymic: "Incorrect patronymic",
    
    requiredSurname: "Enter your last name",
    incorrectSurname: "Incorrect last name",
    
    requiredComment: "Enter your request text",
    incorrectComment: "Incorrect request text",
    incorrectMinLengthComment: "Please provide more details",

    requiredWebsite: "Enter the website address",
    incorrectWebsite: "Incorrect website address",

    requiredDescriptionActivity: "Describe the activities of the legal entity or individual entrepreneur",
    incorrectDescriptionActivity: "Incorrect description of the activities of the legal entity or individual entrepreneur",
    incorrectMinLengthDescriptionActivity: "Describe the activities of the legal entity or individual entrepreneur in more detail",
    
    requiredAgreement: "Your consent to the Personal Data Processing Policy is required",
    
    incorectMaxFiles: "Maximum number of files is 10",
  },
    
  fileButton: {
    selectFile: "Select file",
    replaceFile: "Replace file",
    loadingFile: "Loading file...",
    loadingFileSuccess: "File uploaded",
    loadingFilesSuccess: "Files uploaded",
    filetryAgain: "Try again",
  },
  
  submit: "Send",
}
export default {
  confirmationConsentProcessingPD: "Условиями обработки и использования моих персональных данных",
  confirmationLabel: "Я ознакомлен и согласен с %(linkToConsentProcessingPD)s и %(linkToPrivacyPolicy)s",
  confirmationPrivacyPolicy: "Политикой обработки персональных данных",

  checkCode: {
    confirm: "Подтвердить",
    codeFromEmail: "Код из email",
    codeInfo: "Код отправлен на %(toContact)s %(contact)s. Количество отправок сообщений ограничено.",
    resendCode: "Отправить код повторно",
    resendCodeAfterSomeTime: "Отправить код повторно через %(countdown)s.",
    incorrectCodeEntered: "Введен неверный код",
    formValidationIncorrectAuthCode: "Некорректный код",
    formValidationRequiredAuthCode: "Введите код",
    email: {
      formTitle: "Подтвердите email",
      toContact: "email",
      fromContact: "Код из email сообщения",
      inputCode: "Введите код из email сообщения",
    },
    sms: {
      formTitle: "Подтвердите номер телефона",
      toContact: "номер",
      fromContact: "Код из смс",
      inputCode: "Введите код из смс",
    },
    codeErrorMessages: {
      codeInputLimitExceeded: "Превышено количество попыток ввода. Обновите страницу и попробуйте повторно отправить ваше обращение",
      codeRequestCountExceeded: "Обновите страницу и повторно заполните ваше обращение в форме обратной связи",
      codeTooManyRequests: {
        title: "Превышено количество запросов",
        description: "Обновите страницу, подождите несколько минут и повторно заполните ваше обращение в форме обратной связи"
      },
      codeNumberInputAttemptsExceeded: {
        title: "Превышено количество попыток ввода",
        description: "Обновите страницу и попробуйте повторно отправить ваше обращение"
      },
      invalidCode: "Введен неверный код подтверждения",
      codeExpired: "Код подтверждения истек. Отправте код повторно",
      allResubmissionsExhausted: "Превышено количество запросов кода аутентификации",
      mainTimeHasExpired: "Время подтверждения истекло",
      pleaseRetry: "Обновите страницу и попробуйте повторно отправить ваше обращение",
      email: {
        codeAlreadyChecked: "Email уже подтвержден",
      },
      sms: {
        codeAlreadyChecked: "Номер телефона уже подтвержден",
      }
    },
    codeSuccessMessages: {
        numberConfirmed: "Ваша заявка успешно отправлена",
        ourSpecialistsWillContactYou: "Спасибо за обращение. Ожидайте ответа от специалиста службы поддержки",
    }
  },

  formUser: {
    title: "Опишите ваш запрос",
    result: {
      button: "Хорошо",

      success: "Спасибо за обращение!",
      successDescription: "Ожидайте ответа от специалиста службы поддержки.",
      
      error: "Что-то пошло не так",
      errorDescription: "Мы уже знаем об этой проблеме и скоро всё починим",
    },
  },

  formCompanyLead: {
    title: "Оставьте онлайн-заявку",
    result: {
      button: "Хорошо",

      success: "Спасибо за вашу заявку, мы обязательно с вами свяжемся",

      error: "Что-то пошло не так",
      errorDescription: "Мы уже знаем об этой проблеме и скоро всё починим",
    },
  },

  formProcess: "Записываем...",

  fields: {
    name: "Имя",
    surname: "Фамилия",
    patronymic: "Отчество",
    phone: "Телефон",
    email: "Email",
    eMail: "Электронная почта",
    comment: "Комментарий",

    companyName: "Юридическое лицо или ИП",
    companyIdentificationNumber: "ИНН",
    companyDescriptionActivity: "Описание деятельности",

    website: "Веб-сайт",
  },

  fieldValidation: {

    requiredEmail: "Введите e-mail",
    incorrectEmail: "Введите корректный e-mail",

    requiredEMail: "Введите электронную почту",
    incorrectEMail: "Введите корректную электронную почту",

    requiredCompanyOrIndividualEntrepreneur: "Введите наименование юридического лица или ИП",
    incorrectCompanyOrIndividualEntrepreneur: "Некорректное наименование юридического лица или ИП",

    requiredCompanyIdentificationNumber: "Введите ИНН компании",
    incorrectCompanyIdentificationNumber: "Некорректный ИНН",
    
    requiredPhone: "Введите номер телефона",
    incorrectPhone: "Номер телефона некорректен",
    
    requaredName: "Введите ваше имя",
    incorrectName: "Некорректное имя",

    incorrectPatronymic: "Некорректное отчество",

    requiredSurname: "Введите вашу фамилию",
    incorrectSurname: "Некорректная фамилия",

    requiredComment: "Введите текст обращения",
    incorrectComment: "Некорректный текст обращения",
    incorrectMinLengthComment: "Опишите подробнее",

    requiredWebsite: "Введите адрес веб-сайт",
    incorrectWebsite: "Некорректный адрес веб-сайта",

    requiredDescriptionActivity: "Опишите деятельность юридического лица или ИП",
    incorrectDescriptionActivity: "Некорректное описание деятельности юридического лица или ИП",
    incorrectMinLengthDescriptionActivity: "Опишите подробнее деятельность юридического лица или ИП",

    requiredAgreement: "Необходимо ваше согласие с Политикой обработки ПД",
    
    incorectMaxFiles: "Максимальное кол-во файлов 10",
  },

  fileButton: {
    selectFile: "Выбрать файл",
    replaceFile: "Заменить файл",
    loadingFile: "Загрузка файла...",
    loadingFileSuccess: "Файл загружен",
    loadingFilesSuccess: "Файлы загружены",
    filetryAgain: "Попробуйте ещё раз",
  },

  submit: "Отправить",
}
